import React from 'react';
import { ColorSelectorProps } from '../types/ColourSelector';

const ColorSelector: React.FC<ColorSelectorProps> = ({ onChange }) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  return (
    <div>
      <label htmlFor="colorPicker"> Choose Text Color: </label>
      <input type="color" id="colorPicker" onChange={handleChange} />
    </div>
  );
};

export default ColorSelector;
