import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { wastewaterData } from "../types/WastewaterData";
import { getAllData } from "../services/dataServices";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
);

const ParameterBarGraph: React.FC = () => {
    const [data, setData] = useState<wastewaterData[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<Error | null>(null);

    // fetchs the data
    useEffect(() => {
        const fetchData = async () => {
            try {
                const responseData = await getAllData();
                setData(responseData.slice(0, 100));
                setLoading(false);
            } catch (err) {
                setError(err as Error);
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    if (loading) {
        return <div>Loading data...</div>;
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    // filters and maps data based on parameter names
    const filterAndConvertData = (parameterName: string) => {
        const filteredData = data.filter(
            (entry) => entry.parameterName === parameterName,
        );

        // converts parameter values from string to float
        const values = filteredData.map((entry) => {
            const value = parseFloat(entry.parameterValue);
            return isNaN(value) ? 0 : value;
        });

        return values;
    };

    // prepares the datasets for the bar graph
    const barGraphData = {
        labels: Array(10).fill(""),
        datasets: [
            {
                label: "Phosphorus",
                data: filterAndConvertData("PHOSPHORUS,UNFILTERED TOTAL"),
                backgroundColor: "rgba(54, 162, 235, 0.5)",
            },
            {
                label: "Residue",
                data: filterAndConvertData("RESIDUE, PARTICULATE"),
                backgroundColor: "rgba(255, 159, 64, 0.5)",
            },
        ],
    };

    // chart options (for UI)
    const options = {
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
            legend: {
                position: "top" as const,
            },
            title: {
                display: true,
                text: "Parameter Values Graph",
            },
        },
        scales: {
            x: {
                ticks: {
                    display: false,
                },
                grid: {
                    display: false,
                },
            },
            y: {
                beginAtZero: true,
            },
        },
    };

    return (
        <div>
            {/* adjusts chart size */}
            <h2>Parameter Values Graph</h2>
            <p>
                The graph above shows the levels of Phosphorus and Residue in
                the waste water levels
            </p>
            <p>
                Phosphorus and residue were picked out of the two because it is
                the simpliest for an average person to understand
            </p>
            <div className="graph-bg">
                {/* renders the bar graph*/}
                <Bar data={barGraphData} options={options} />
            </div>
        </div>
    );
};

export default ParameterBarGraph;
