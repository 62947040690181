import { wastewaterData } from "../types/WastewaterData";

//Finds the API
const API_URL = "/api/wastewater";

export const getAllData = async (): Promise<wastewaterData[]> => {
    try {
        // wait for fetch request
        const response = await fetch(`${API_URL}/all`);
        if (!response.ok) {
            throw new Error(`HTTP Error: ${response.status}`);
        }
        console.log(response);
        // wait for to json
        const data = await response.json();
        return data;
    } catch (err) {
        console.error(`Error fetching data: ${err}`);
        return [];
    }
};
