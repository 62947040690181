import React from "react";
import { SplashPageProps } from "../types/SplashPage";

//all the UI for the splash page

const SplashPage: React.FC<SplashPageProps> = ({ onGoToHomePage }) => {
    return (
        <div className="splash-page-info">
            <h1>WaterWize</h1>
            <h2>Project Description</h2>
            <p>
                <strong>
                    Introducing The WaterWize App: Your Essential Tool for Water
                    Quality Monitoring and Trend Analysis!
                </strong>
            </p>
            <p>
                Are you concerned about the quality of water in your local
                streams and lakes? Wondering how industrial activity might be
                affecting your environment? Look no further! Team Aguaslice
                presents WaterWize, an intuitive app designed to track and
                analyze trends in water quality over time. Using real data from
                streams, lakes, and industrial wastewater sources, WaterWize
                helps you visualize how industrial discharge impacts water
                quality. With features like dynamic heat maps and interactive
                graphs, the app empowers users to easily identify areas at risk,
                making it the perfect tool for environmental monitoring and
                decision-making.
            </p>
            <h2>Project Pitch</h2>
            <h3>The Problem and Solution</h3>
            <p>
                Water quality is a growing global concern, especially in regions
                affected by industrial activity. However, raw data is just too
                hard to make any sense of. Environmental agencies, policymakers,
                and everyday people struggle to make informed decisions without
                clear insights into the data of how industrial wastewater is
                affecting nearby water bodies like lakes and streams. The lack
                of user-friendly data analyzers and interpreters allows
                potential risk areas to go unnoticed, increasing the likelihood
                of environmental and health hazards. This is where The WaterWize
                App comes in! The WaterWize App provides a user-friendly,
                data-driven platform that solves this problem by offering
                detailed water quality monitoring, analysis, and insights. Our
                app uses data on stream water quality, lake water quality, and
                industrial wastewater to track trends and identify potential
                contamination. Through features like spatial analysis
                integration, users can visually explore safe and unsafe regions
                using dynamic heat maps. The regression and correlation analysis
                tools enhance decision-making by predicting future water quality
                changes based on current data trends. In addition, our
                interactive data visualization allows users to engage with the
                data in a meaningful way, customizing graphs to view daily and
                hourly trends or deep-diving into specific contaminants. For
                real-time monitoring, our current status monitor webpage offers
                stakeholders a simple interface to track water conditions and
                take action when necessary. All of these features empower
                everyone regardless of their ability to understand complex data
                to make decisions that support their health and well-being.
            </p>
            <h3>Target Users</h3>
            <p>
                Our target users include environmental agencies, local
                governments, industrial regulators, and everyday citizens. These
                stakeholders need tools to easily visualize water trends, assess
                future risks, and make informed decisions regarding public
                health and safety.
            </p>
            <h3>Scope</h3>
            <ol>
                <li>
                    <p>
                        Dynamic heat maps to visualize water quality in specific
                        regions
                    </p>
                </li>
                <li>
                    <p>Interactive graphs to track long-term trends</p>
                </li>
                <li>
                    <p>
                        Spatial analysis tools to connect industrial activities
                        to water quality changes
                    </p>
                </li>
                <li>
                    <p>
                        Regression analysis to predict future contamination
                        levels
                    </p>
                </li>
                <li>
                    <p>
                        A current status monitor webpage to stay up-to-date with
                        real-time data
                    </p>
                </li>
            </ol>
            <h3>Why Invest?</h3>
            <p>
                Investing in the WaterWize App means supporting an important
                solution to the growing issue of water quality monitoring,
                especially in areas impacted by industrial activity. WaterWize
                enables users to visualize and interpret water quality data,
                making it accessible to environmental agencies, local
                governments, and everyday citizens. With user-friendly features
                like dynamic heat maps, spatial analysis, and real-time
                monitoring, the app empowers users to make informed decisions
                that protect public health and the environment. By investing in
                WaterWize, you are supporting a tool that turns data into
                actionable insights, driving sustainable solutions for water
                management.
            </p>

            <div className="splash-page-button-div">
                <button className="splash-page-button" onClick={onGoToHomePage}>
                    Let's get started
                </button>
            </div>
        </div>
    );
};

export default SplashPage;
