import React, { useEffect, useState } from "react";
import { getAllData } from "../services/dataServices";
import { wastewaterData } from "../types/WastewaterData";

const SectorList: React.FC<NonNullable<unknown>> = () => {
    // A dictionary to map sector names to their descriptions
    let dict: { [key: string]: string } = {
        "ELECTRIC POWER GENERATION":
            "Generation, transmission, and distribution of electrical power",
        "INDUSTRIAL MINERALS":
            "Rocks, minerals, and naturally occurring materials that are mined for economic value",
        "INORGANIC CHEMICALS":
            "Chemical manufacturing, the production and sale of inorganic compounds",
        "IRON AND STEEL":
            "Steel and iron manufacturing, extracting of iron ore, refinement and production of steel",
        "METAL CASTING": "Creating metal parts into a desired shape for sale",
        "METAL MINING AND REFINING":
            "Extraction and processing of metals from natural mineral deposits for distribution",
        "ORGANIC CHEMICAL MANUFACTURING":
            "Production of organic chemicals for distribution",
        "PETROLEUM REFINERIES":
            "Extraction, refining, and distribution of petroleum and its products for sale",
    };

    // State to store fetched data
    const [data, setData] = useState<string[]>();

    // State to handle loading state while fetching data
    const [loading, setLoading] = useState(true);

    // State to capture any errors during data fetching
    const [error, setError] = useState<Error | null>();

    // useEffect to fetch data when the component mounts
    useEffect(() => {
        const wastewaterData = getAllData(); // Fetch data from the service

        // Handle the promise returned by the service
        wastewaterData
            .then((data: wastewaterData[]) => {
                console.log(data); // Log the fetched data (for debugging)

                // Extract unique sector names from the fetched data
                setData([...new Set(data.map((entry) => entry.sector))]);

                setLoading(false); // Set loading to false once data is fetched
            })
            .catch((err: Error) => {
                setError(err); // Capture any errors
                setLoading(false); // Stop loading on error as well
            });
    }, []);

    // If still loading, show loading message
    if (loading) {
        return <div>Fetching data...</div>;
    }

    // If there's an error, display the error message
    if (error) {
        return <div>Error {error.message}</div>;
    }

    // Render the table if data is successfully fetched
    return (
        <div>
            {data && data.length > 0 && (
                <table className="table-water-quality">
                    <thead>
                        <tr>
                            <th className="table-header">Sector</th>
                            <th className="table-header">Description</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((entry: string, index) => (
                            <tr key={index}>
                                <td className="table-cell">{entry}</td>
                                {/* Display sector name */}
                                <td className="table-cell">
                                    {dict[entry.toString()]}
                                </td>
                                {/* Display corresponding sector description */}
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </div>
    );
};

export default SectorList;
