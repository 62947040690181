import React, { useState } from "react";
import SplashPage from "./components/SplashPage";
import SectorList from "./components/SectorList";
import ParameterBarGraph from "./components/ParameterBarGraph";
const App: React.FC = () => {
    //triggers the "Let's get started" button to go to the homepage of the app
    const [showHomePage, setShowHomePage] = useState(false);

    const handleGoToHomePage = () => {
        setShowHomePage(true);
    };

    return (
        //shows the splash page after the "let's get started button is clicked"
        <div className="App">
            {showHomePage ? (
                <header className="App-header">
                    <p>Welcome to our WaterWize App!</p>

                    <p>
                        Here is a quick rundown of what sectors are contributing
                        <br /> to industrial wastewater and a short description.
                    </p>
                    <SectorList />
                    <ParameterBarGraph />
                </header>
            ) : (
                <SplashPage onGoToHomePage={handleGoToHomePage} />
            )}
        </div>
    );
};

export default App;
