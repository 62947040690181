import React, { useState } from 'react';

const Instructions: React.FC = () => {
  const [showInstructions, setShowInstructions] = useState(true);

  const handleClose = () => {
    setShowInstructions(false);
  };

  const handleViewInstructions = () => {
    setShowInstructions(true);
  };

  return (
    <>
      {!showInstructions && (
        <button onClick={handleViewInstructions}>View Instructions</button>
      )}

      {showInstructions && (
        <div className="modal">
          <div className="modal-content">
            <h2>Instructions</h2>
            <p>Welcome to the TODO Notes Application!</p>
            <ul>
              <li>To add a new note, type your text in the text box and click the "Add Note" button.</li>
              <li>You can view all your notes in the list below the "All Notes" label.</li>
              <li>Your notes will be saved automatically.</li>
            </ul>
            <button onClick={handleClose}>Got it!</button>
          </div>
        </div>
      )}
    </>
  );
};

export default Instructions;
