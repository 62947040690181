import React from 'react';
import { BgSelectorProps } from '../types/BgSelector';

const BgSelector: React.FC<BgSelectorProps> = ({onChange}) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files?.[0];
      if (file) {  
        onChange(URL.createObjectURL(file));
      }
    };

    return (
        <div>
            <input className="file-input" id="fileInput" type="file" accept="image/*" onChange={handleChange}/>
            <label className="custom-file-upload" htmlFor="fileInput"> Upload Image </label>
        </div>
    );
};

export default BgSelector;