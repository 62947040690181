import React, { useState } from 'react';
import AddNote from './components/AddNote';
import NoteList from './components/NoteList';
import BgSelector from './components/BgSelector';
import Instructions from './components/Instructions';
import ColorSelector from './components/ColourSelector';

const App: React.FC = () => {
  // Get the current date
  const dateTime = new Date();
  const formattedDate = dateTime.toLocaleString(); // Format the date and time

  const [bgUrl, setBgUrl] = useState('');
  const [refreshNotes, setRefreshNotes] = useState(false); // State to trigger note refresh
  const [textColor, setTextColor] = useState<string>('#000000');

  const handleBgChange = (url: string) => {
    setBgUrl(url);
  };

  const triggerRefresh = () => {
    setRefreshNotes(prev => !prev); // Toggle state to trigger refresh
  };

  const handleColorChange = (color: string) => {
    setTextColor(color); // Update the text color state
  };

  return (
    <div className="App" style={{ backgroundImage: `url(${bgUrl})`, color: textColor}}>
      <h1>TODO Notes Application</h1>
      <p>Current Date: {formattedDate}</p> {/* Display the current date */}

      <Instructions />
      <AddNote onNoteAdded={triggerRefresh} />
      <BgSelector onChange={handleBgChange}/>
      <ColorSelector onChange={handleColorChange} />
      <NoteList refreshNotes={refreshNotes}/>
    </div>
  );
};

export default App;